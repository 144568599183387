<template>
  <div>
    <b-button
      v-if="!noBtn"
      variant="outline-primary"
      @click="toggleModal()"
    >
      <feather-icon
        icon="UploadIcon"
      />
      {{ $t('credits.upload_proof') }}
    </b-button>
    <b-modal
      v-model="isUploadSlipModalActive"
      :title="$t('credits.upload_proof')"
      hide-footer
    >
      <form @submit.prevent="onSubmit">
        <b-form-group label-for="proof">
          <b-form-file
            id="proof"
            v-model="file"
            accept="image/*"
          />
          <b-form-text>
            <b-link
              v-if="file"
              class="text-danger"
              @click="file = ''"
            >
              {{ $t('buttons.remove') }}
            </b-link>
          </b-form-text>
        </b-form-group>
        <div class="text-right">
          <b-button
            variant="light"
            class="mr-2"
            @click="onCloseModal"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-overlay
            :show="credit.isUploadingSlipFile"
            rounded
            spinner-small
            class="d-inline-block"
          >
            <b-button
              type="submit"
              variant="primary"
              block
            >
              {{ $t('buttons.confirm') }}
            </b-button>
          </b-overlay>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  props: {
    noBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isUploadSlipModalActive: false,
      file: null,
    }
  },
  computed: {
    ...mapState(['credit']),
  },
  methods: {
    ...mapActions(['uploadSlipFile']),
    toggleModal() {
      this.isUploadSlipModalActive = !this.isUploadSlipModalActive
    },
    onCloseModal() {
      this.isUploadSlipModalActive = false
    },
    async onSubmit() {
      await this.uploadSlipFile({ file: this.file })
      this.$emit('submit')
      this.onCloseModal
    },
  },
}
</script>